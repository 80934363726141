// import "./index.css";
import React from "react";
import { TagCloud } from "@frank-mayer/react-tag-cloud";
// same as: import TagCloud from "@frank-mayer/react-tag-cloud"

const MyCloud = () => {
  const myTags = [
    "Go",
    "C++",
    "Python",
    "JavaScript",
    "CSS",
    "HTML5",
    "React",
    "Java",
    "git",
    "AWS",
    "PostgreSQL",
    "Docker",
    "Kubernetes",
    "Terraform",
    "gRPC",
    "Identity and Access Management",
    "OIDC",
    "SAML",
  ];

  return (
    <div>
      <TagCloud
        options={(w: Window & typeof globalThis): TagCloudOptions => ({
          radius: Math.min(500, w.innerWidth, w.innerHeight) / 2,
          maxSpeed: "fast",
        })}
      >
        {myTags}
      </TagCloud>
    </div>
  );
};

// const MyCloud = () => {
//   var tagCloud = TagCloud(".tagcloud-container", myTags, {
//     // radius in px
//     radius: 250,

//     // animation speed
//     // slow, normal, fast
//     maxSpeed: "fast",
//     initSpeed: "fast",

//     // 0 = top
//     // 90 = left
//     // 135 = right-bottom
//     direction: 135,

//     // interact with cursor move on mouse out
//     keep: true,
//   });

//   return (
//     <div>
//       <span className="tagcloud-container"></span>
//     </div>
//   );
// };

export default MyCloud;
